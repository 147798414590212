import React from "react";
import "./App.css";
import logo from "./WL_logo_turquoise.png";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />{" "}
        <p>🚧 rb-admin-portal : under construction 🚧</p>
        <p className="App-intro">
          Any questions? Contact us at{" "}
          <code> dl-gopay-sleipnir@worldline.com </code> or{" "}
          <code> #gopay-sleipnir </code> on Slack.
        </p>
        <div>
          <button
            className="App-link"
            onClick={() => window.open("https://example.com")}
          >
            Back to the Landing page
          </button>
        </div>
      </header>
    </div>
  );
}

export default App;
